import {
  SideNavInterface
} from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [{
  path: '/dashboard',
  title: 'Dashboard',
  iconType: 'nzIcon',
  iconTheme: 'outline',
  icon: 'appstore-add',
  submenu: []
},
{
  path: '',
  title: 'Transactions',
  iconType: 'nzIcon',
  iconTheme: 'outline',
  icon: 'rise',
  submenu: [
    {
      path: '/lms/transactions/issue-book',
      title: 'Issue Book',
      iconType: '',
      icon: '',
      iconTheme: '',
      submenu: []
    },
    {
      path: '/lms/transactions/return-book',
      title: 'Return Book',
      iconType: '',
      icon: '',
      iconTheme: '',
      submenu: []
    },
  ]
},
{
  path: '',
  title: 'Meta & Storage',
  iconType: 'nzIcon',
  iconTheme: 'outline',
  icon: 'lock',
  submenu: [
    {
      path: '/lms/config/books',
      title: 'Books',
      iconType: '',
      icon: '',
      iconTheme: '',
      submenu: []
    },
    {
      path: '/lms/config/members',
      title: 'Members',
      iconType: '',
      icon: '',
      iconTheme: '',
      submenu: []
    },
    {
      path: '/lms/config/categories',
      title: 'Categories',
      iconType: '',
      icon: '',
      iconTheme: '',
      submenu: []
    },
    {
      path: '/lms/config/authors',
      title: 'Author',
      iconType: '',
      icon: '',

      iconTheme: '',
      submenu: []
    },
    {
      path: '/lms/config/publishers',
      title: 'Publisher',
      iconType: '',
      icon: '',
      iconTheme: '',
      submenu: []
    }
  ]
},
]
