import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {TokenStorageService} from './token-storage.service';
import { AuthService } from './auth.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
//import {CustomToastService} from "../../modules/shared/services/custom-toast.service";

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const tokenService = inject(TokenStorageService);
    const authService = inject(AuthService);
    const notifiactionService = inject(NzNotificationService);
    //const toastService = inject(CustomToastService);

    // Clone the request object
    let newReq = req.clone();
    let currentUrl = req.url;

    if (tokenService.getToken() !== null && currentUrl.indexOf("api.cloudinary.com") === -1) {        
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + tokenService.getToken()),
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            console.log(error);
            if(error instanceof  HttpErrorResponse && error.status === 400) {
                notifiactionService.error("Error",error.error.responseMessage);
                //toastService.error(error.error.responseMessage);
            }
            if (error instanceof HttpErrorResponse && error.status === 401) {
                authService.logout();
            }

            return throwError(error);
        }),
    );
};
