import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from 'src/app/models/api-response.model';
import { AuthRequest } from 'src/app/models/auth/auth-request.modal';
import { AuthResponse } from 'src/app/models/auth/auth-response.modal';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class AuthService {

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenStorageService,
        private router: Router
    ) { }

    /**
     * Auth User
     * @param loginObj
     */
    loginUser(loginObj: AuthRequest): Observable<APIResponse<AuthResponse>> {
        return this._httpClient.post<APIResponse<AuthResponse>>('/lmsapi/auth/login', loginObj, httpOptions);
    }

    redirectUser() {
        this.router.navigate(['/dashboard']);
    }

    logout() {
        this._tokenService.signOut();
        this.router.navigate(['/']);
    }

}
