import { Injectable } from '@angular/core';
import { AuthUserDetails } from 'src/app/models/user/auth-user-details.modal';

const USER_KEY = 'auth-user';
const TOKEN_KEY = 'auth-token';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    constructor() { }

    /**
    * Decoding Token
    * @param token
    * @returns User
    */
    decodeToken(token: string): any {
        return JSON.parse(atob(token.split('.')[1]));
    }

    signOut(): void {
        window.localStorage.clear()
    }

    saveToken(token: string): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    getToken() {
        //return "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NzI1OTU2NTk5Iiwic2NvcGVzIjpbeyJhdXRob3JpdHkiOiJBRE1JTiJ9XSwiZW1haWwiOiJyZXBseTJ2aXZla3NoYWhAZ21haWwuY29tIiwibW9iaWxlIjoiOTcyNTk1NjU5OSIsImxvZ2luVXNlcklkIjoxLCJidXNpbmVzc0lkIjoxLCJidXNpbmVzc0xvY2F0aW9uSWQiOjEsImZ1bGxOYW1lIjoiVml2ZWsgU2hhaCIsImlhdCI6MTcwNDk2NDUzOSwiZXhwIjoxNzA0OTgyNTM5fQ.KibxYWWHzfjDWt5jC-5SCloxlIGInt9eNzfBWI4uug4";
        return window.localStorage.getItem(TOKEN_KEY);
    }

    getUser(): AuthUserDetails {
        return this.decodeToken(this.getToken());
    }
}

