import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [

    //Dashboard
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },

    {
        data: {
            title: "Cofigure LMS",
        },
        title: "Cofigure LMS",
        path: 'lms/config',
        loadChildren: () => import('../../lms/lms-config/lms-config.module').then(m => m.LmsConfigModule),
    },
    {
        data: {
            title: "Manage Transactions",
        },
        title: "Manage Transactions",
        path: 'lms/transactions',
        loadChildren: () => import('../../lms/lms-transactions/lms-transactions.module').then(m => m.LmsTransactionsModule),
    }
];
